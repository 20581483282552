<template>
  <div>
    <select-input
      label="Gender"
      name="sex"
      ref="patientSex"
      id="sex"
      :items="sexes"
      v-model="sex"
      :searchEnabled="false"
      data-private="redact"
      :accessKey="accessKey"
      :highlightSearchValue="highlightSearchValue"
      :validator="validator"
    />
  </div>
</template>

<script>
import SelectInput from "@/components/common/SelectInput.vue";
import { mapState } from "vuex";
export default {
  components: { SelectInput },
  props: ["value", "accessKey", "highlightSearchValue", "validator"],
  data() {
    return {
      sexEnum: {
        m: 0,
        f: 1,
        o: 2,
        u: 3
      }
    };
  },
  computed: {
    ...mapState({
      sexes: state => state.dropdowns.sexes
    }),
    sex: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    }
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeydown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
  methods: {
    focus() {
      this.$refs.patientSex.focus();
    },
    handleKeydown(e) {
      if ("mfouMFOU".includes(e.key) && this.$refs?.patientSex?.isFocused) {
        this.sex = this.sexEnum[e.key.toLowerCase()];
        this.$refs.patientSex.focusOut();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
